import { t } from '@lib/i18n';
import Image from '@ui/Image';
import Slider from '@ui/Slider';
import Typography from '@ui/Typography';
import { useMediaQuery } from '@hooks/useMediaQuery';
import clsx from 'clsx';

import PlayLogo from '../../../../../static/images/play.svg';
import Chevron from '../../../../../static/images/chevron-down.svg';

const Card = ({ item }) => (
  <a
    href={item.link}
    target="_blank"
    className="border-b-solid group flex h-full flex-col justify-between border-b border-b-tertiary300 pb-1.5 pt-6 sm:h-[428px] md:h-[422px]"
  >
    <div className="transition duration-300 group-hover:translate-y-[-24px]">
      <Image src={`/images/blog/${item.image}`} alt={item.image_alt} />
      <Typography className="mt-3 text-xl font-semibold lg-down:text-lg">
        {item.title}
      </Typography>
    </div>
    <div className="mt-5 flex items-center justify-between">
      <Typography color="secondary100" className="text-2xs uppercase group-hover:text-primary400">
        {item.duration}
      </Typography>
      <PlayLogo className="fill-secondary100 group-hover:fill-primary400" />
    </div>
  </a>
);

const CustomArrow = (props) => {
  const {
    className, style, onClick, addClassName, 
  } = props;
  return (
    <div
      className={clsx(
        className, 
        addClassName,
        'absolute !top-[-18px] !h-6 !w-6 rounded-full !py-[10px] shadow-[0px_8px_12px_rgba(17,41,80,0.12)] hover:border hover:border-solid hover:border-tertiary300 hover:bg-tertiary100 md:!h-5 md:!w-5 md:!py-[6px]',
      )}
      style={{ ...style }}
      onClick={onClick}
    >
      <Chevron className="m-auto h-3.5 w-3.5" />
    </div>
  );
};

const VideoSection = () => {
  const isTablet = useMediaQuery('(max-width: 1023px)');
  const isMobile = useMediaQuery('(max-width: 687px)');

  const videos = t('blog.video.cards');
  return (
    <div className="relative mx-3 rounded-t-2xl border border-b-0 border-solid border-tertiary200 bg-[linear-gradient(180deg,#FFFFFF_0%,rgba(255,255,255,0.817367)_23.29%,rgba(255,255,255,0.563662)_58.48%,rgba(255,255,255,0)_100%)] pt-9 pb-6 lg-down:mx-1">
      <div className="grid-layout mx-auto">
        <Typography className="text-4xl font-semibold">
          {t('blog.video.title')}
        </Typography>
        <Slider
          settings={{
            slidesToShow: isMobile ? 1 : isTablet ? 2 : 3, 
            arrows: !isMobile, 
            nextArrow: <CustomArrow addClassName="!right-0 !pr-[8px] !pl-[12px] md:!pr-[5px] md:!pl-[7px]" />, 
            prevArrow: <CustomArrow addClassName="right-[60px] md:right-[52px] !left-auto !pr-[12px] !pl-[8px] md:!pl-[5px] md:!pr-[7px]" />, 
          }}
          gap={4}
        >
          {videos.map((video, i) => (
            <Card item={video} key={video.link} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default VideoSection;
