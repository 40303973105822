import {
  PostCard,
  PostsByCategory,
  RecommendedPosts,
  useSitePosts,
} from '@entities/post';
import { SubscribeForm } from '@features/subscribe';
import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import { Layout } from '@widgets/layout';
import { VideoSection } from './ui/VideoSection';
import { LeadMagnitBanner } from './ui/LeadMagnitBanner';

export const BlogV2 = () => {
  const groupedPosts = useSitePosts();

  return (
    <Layout seo={t('seo.blog')} banner bodyClassName="bg-tertiary100">
      <Layout.Intro>
        <div className="grid-layout mx-auto flex flex-col items-center pb-5 lg-down:pb-[53px]">
          <Typography variant="header1" className="lg:pt-11 lg-down:pt-7 lg-down:text-center">
            {t('blog.header')}
          </Typography>
          <Typography variant="subhead1" className="mt-4 lg-down:text-center">
            {t('blog.subheader')}
          </Typography>
          <SubscribeForm />
          <PostCard
            post={groupedPosts.general !== undefined ? groupedPosts.general[0] : groupedPosts.all[0]}
            isGeneral
            className="lg:mt-6 lg-down:mt-5"
          />
        </div>
      </Layout.Intro>
      <div className="gradient_light relative -mt-2 sm:mx-1 md:mx-2 lg:mx-3">
        <RecommendedPosts
          className="lg:pt-12 lg:pb-9 lg-down:pt-7"
          listClassName="lg:mt-6 lg-down:mt-5"
          posts={groupedPosts.recommended}
          small
        />
      </div>
      <div className="grid-layout mx-auto sm:mb-12 md:mb-6 lg:my-9 lg-down:mt-7">
        <PostsByCategory posts={groupedPosts} />
      </div>
      <LeadMagnitBanner />
      <VideoSection />
    </Layout>
  );
};
